<template>
  <div class="pt-4 pb-5 h-100">
    <Tabs :tabs="tabs" :active-tab.sync="activeTab" class="px-5" />
    <div style="height: calc(100% - 57px)">
      <OrderHistoryTable v-show="activeTab === 0" :product="product" @change="orderCount = $event" />
      <DeliveryHistoryTable v-show="activeTab === 1" :product="product" @change="deliveryCount = $event" />
    </div>
  </div>
</template>

<script>
import { Tabs } from '@/modules/core';

import OrderHistoryTable from './OrderHistoryTable';
import DeliveryHistoryTable from './DeliveryHistoryTable';

export default {
  components: { Tabs, OrderHistoryTable, DeliveryHistoryTable },
  props: {
    product: { type: Object, required: true },
  },
  data() {
    return {
      activeTab: 0,
      orderCount: 0,
      deliveryCount: 0,
    };
  },
  computed: {
    tabs() {
      return [
        {
          text: this.$t('productModal.eventHistory.tab.orders'),
          badgeValue: this.orderCount,
        },
        {
          text: this.$t('productModal.eventHistory.tab.deliveries'),
          badgeValue: this.deliveryCount,
        },
      ];
    },
  },
};
</script>
