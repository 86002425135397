export const getSectionsByLevel = (currentPath, sectionsTrees, sectionLevel) => {
  const pathToSection = [...currentPath.slice(0, sectionLevel)];
  let subSection = { sections: sectionsTrees };

  for (const pathIndex of pathToSection) {
    subSection = subSection.sections[pathIndex];
  }

  if (!subSection.sections) {
    subSection.sections = [];
  }
  return subSection.sections;
};
