<template>
  <div v-loading="isLoading" class="card shadow text-break ordered-product-pricing">
    <div class="card-header fw-bold total-pricing">
      <div>
        {{ $t(`${i18nPrefix}.latestOrderedPrice`) }}
      </div>
      <div>{{ netAmountPerUnit }}</div>
    </div>

    <div class="card-body">
      <table class="table table-sm mb-2">
        <th class="fw-bold mb-2 border-bottom-0">
          {{ $t(`${i18nPrefix}.pricingDetails`) }}
        </th>
        <tbody class="pricing-details">
          <tr v-if="productBasePrice">
            <td class="pricing-row border-top-0 border-bottom-0">
              <div>
                {{ $t(`${i18nPrefix}.basePrice`) }}
              </div>
              <div>
                {{ productBasePrice }}
              </div>
            </td>
          </tr>
          <tr v-if="productDiscountPercentage">
            <td class="pricing-row border-bottom-0">
              <div>
                {{ $t('terms.supplierTerms.supplierTermTable.itemDiscount') }}
                ({{ productDiscountPercentage }}%)
              </div>
              <div>{{ productDiscountAmount }}-</div>
            </td>
          </tr>
          <tr v-if="orderDiscountPercentage">
            <td class="pricing-row border-bottom-0">
              <div>
                {{ $t('terms.supplierTerms.supplierTermTable.orderDiscount') }}
                ({{ orderDiscountPercentage }}%)
              </div>
              <div>{{ orderDiscountAmount }}-</div>
            </td>
          </tr>
          <tr v-for="associatedItem in productAssociatedItems" :key="associatedItem.id">
            <td class="pricing-row border-bottom-0">
              <div>
                {{ associatedItem.name }}
              </div>
              <div>
                {{ associatedItem.netAmountPerUnit }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

import { useTenancy } from '@/modules/auth';
import { useCurrency } from '@/locale/useCurrency';

import { MISSING_DATA_TEXT } from '@/modules/purchase-management/tools/constants';

import { formatCurrencyOrDash } from '@/modules/purchase-management/purchaseManagementFormatters';
import { useProductOrderItemOrderItems } from '../compositions/orderedProductOrderItem';

export default {
  props: {
    product: { type: Object, default: null },
  },
  setup(props) {
    const { currencyFormat } = useCurrency();

    const i18nPrefix = 'productModal.LatestProductOrderedPricing';
    const { currentTenant } = useTenancy();
    const variables = computed(() => ({
      businessId: currentTenant.value.id,
      productId: props.product?.id,
      limit: 1,
    }));

    const { orderItems, loading: loadingOrderItems } = useProductOrderItemOrderItems(variables);
    const isLoading = computed(() => {
      return loadingOrderItems.value;
    });
    const productOrderItem = computed(() => {
      return orderItems.value.find((item) => item.productId == props.product.id);
    });

    const netAmountPerUnit = computed(() =>
      formatCurrencyOrDash(productOrderItem?.value?.netAmountPerUnit, currencyFormat.value)
    );

    const productBasePrice = computed(() => formatCurrencyOrDash(productOrderItem?.value?.price, currencyFormat.value));

    const productDiscountAmount = computed(() =>
      formatCurrencyOrDash(productOrderItem?.value?.discountAmount, currencyFormat.value)
    );

    const productDiscountPercentage = computed(() => (productOrderItem.value ? productOrderItem.value.discount : 0));

    const orderDiscountPercentage = computed(() =>
      productOrderItem.value ? productOrderItem.value.orderDiscountRate * 100 : MISSING_DATA_TEXT
    );

    const orderDiscountAmount = computed(() =>
      productOrderItem?.value
        ? formatCurrencyOrDash(productOrderItem?.value?.orderDiscountAmount, currencyFormat.value)
        : 0
    );

    const ASSOCIATED_INTEGRAL_ITEM_TYPE = 'associatedIntegral';
    const productAssociatedItems = computed(() => {
      if (!productOrderItem.value || !productOrderItem.value.items) {
        return [];
      }
      return productOrderItem.value.items
        .filter((item) => item.type === ASSOCIATED_INTEGRAL_ITEM_TYPE)
        .map((item) => {
          return {
            name: item.product.name,
            netAmountPerUnit: formatCurrencyOrDash(item.netAmountPerUnit, currencyFormat.value),
          };
        });
    });
    return {
      i18nPrefix,
      isLoading,
      netAmountPerUnit,
      productBasePrice,
      productDiscountPercentage,
      productDiscountAmount,
      productAssociatedItems,
      orderDiscountPercentage,
      orderDiscountAmount,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.ordered-product-pricing {
  .total-pricing {
    justify-content: space-between;
  }

  .pricing-details {
    .pricing-row {
      display: flex;
      justify-content: space-between;
      margin: 0.25rem;
      margin-inline-start: 1rem;
      margin-inline-end: 0;
      padding: 0.25rem 0 0 0;
      border-top-width: 1px;
      border-bottom-width: 1px;
    }
  }
}
</style>
