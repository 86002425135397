import { render, staticRenderFns } from "./OrderHistoryTable.vue?vue&type=template&id=e87e4564&scoped=true"
import script from "./OrderHistoryTable.vue?vue&type=script&lang=js"
export * from "./OrderHistoryTable.vue?vue&type=script&lang=js"
import style0 from "./OrderHistoryTable.vue?vue&type=style&index=0&id=e87e4564&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e87e4564",
  null
  
)

export default component.exports