<template>
  <div class="h-100">
    <Tabs :tabs="tabs" :active-tab.sync="activeTab" class="px-5" />
    <div class="px-5 my-4 d-flex flex-row-reverse">
      <PeriodNavigator :to-date="toDate" @forward="navigateYear" @backward="navigateYear" />
    </div>
    <div class="px-5" style="height: calc(100% - 123px)">
      <Table
        v-loading="loading"
        class="shadow delivery-history-table mh-100"
        rounded
        border
        :data="tableData"
        :columns="columns"
        show-index
        @row-click="deliveryId = tableData[$event].deliveryId"
      >
        <template #cell-deliveryDate="{ rowData: { deliveryDate } }">
          {{ formatDate(deliveryDate) }}
        </template>

        <template #cell-quantity="{ rowData: { quantity, difference } }">
          <template v-if="isNil(quantity)">-</template>
          <CellWithDifference
            v-else
            :value="Number(quantity).toString()"
            :difference="formatDifference(difference)"
            :supplier-name="product.business.name"
            :business-name="tenantName"
          />
        </template>

        <template #cell-kebab="{ rowIndex, rowData }">
          <el-dropdown
            class="d-flex"
            trigger="click"
            :placement="$direction === 'rtl' ? 'bottom-start' : 'bottom-end'"
            @command="(action) => handleAction(action, rowData)"
            @visible-change="(isVisible) => actionsVisibleChange(rowIndex, isVisible)"
          >
            <Button type="icon" class="p-0 more-btn" :class="{ active: activeActions === rowIndex }" @click.stop>
              <KebabIcon />
            </Button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="MORE_ACTIONS.OPEN_DOCUMENT_MODAL">
                {{ $t('productModal.eventHistory.delivery.more.displayDocument') }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </Table>
    </div>
    <EventMapModal v-if="deliveryId" :activity="{ id: deliveryId, type: 'delivery' }" @close="handleClose()" />
    <DocumentModal v-if="documentId" visible :document-id="documentId" @close="documentId = null" />
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { isNil } from 'ramda';
import { computed, ref } from 'vue';

import { useDeliveryItems } from '@/modules/delivery';
import { useTenancy } from '@/modules/auth';
import { KebabIcon } from '@/assets/icons';
import { EventMapModal } from '@/modules/eventMapModal';
import { DocumentModal } from '@/modules/documentModal';
import { Tabs, Table, Button } from '@/modules/core';

import { formatDate } from '@/modules/purchase-management/purchaseManagementFormatters';

import CellWithDifference from './CellWithDifference';
import PeriodNavigator from './PeriodNavigator';

const DELIVERY_HISTORY_TABLE_HEADER = {
  DELIVERY_DATE: 'deliveryDate',
  QUANTITY: 'quantity',
};

const MORE_ACTIONS = {
  OPEN_DOCUMENT_MODAL: 'openDocumentModal',
};

const TABS_FILTER_PREDICATES = [
  (deliveryItem) => deliveryItem.quantity >= 0,
  (deliveryItem) => deliveryItem.quantity < 0,
];

export default {
  components: { Tabs, Table, Button, EventMapModal, DocumentModal, KebabIcon, CellWithDifference, PeriodNavigator },
  props: {
    product: { type: Object, required: true },
  },
  setup(props) {
    const { currentTenant } = useTenancy();
    const toDate = ref(DateTime.local().endOf('month'));
    const fromDate = computed(() => toDate.value.minus({ years: 1 }).startOf('month'));

    const variables = computed(() => ({
      businessId: currentTenant.value.id,
      productId: props.product?.id,
      toDate: toDate.value.toISODate(),
      fromDate: fromDate.value.toISODate(),
    }));

    const { deliveryItems, loading, refetch } = useDeliveryItems(variables);
    return {
      deliveryItems,
      loading,
      refetch,
      currentTenant,
      toDate,
      isNil,
    };
  },
  data() {
    return {
      MORE_ACTIONS,
      activeTab: 0,
      deliveryId: null,
      documentId: null,
      activeActions: null,
    };
  },
  computed: {
    tabs() {
      return [
        {
          text: this.$t('productModal.eventHistory.delivery.tab.received'),
          badgeValue: this.loading ? 0 : this.deliveryItems.filter(TABS_FILTER_PREDICATES[0]).length,
        },
        {
          text: this.$t('productModal.eventHistory.delivery.tab.returned'),
          badgeValue: this.loading ? 0 : this.deliveryItems.filter(TABS_FILTER_PREDICATES[1]).length,
        },
      ];
    },
    columns() {
      return [
        {
          header: this.$t('productModal.eventHistory.delivery.header.deliveryDate'),
          key: DELIVERY_HISTORY_TABLE_HEADER.DELIVERY_DATE,
          width: '20%',
        },
        {
          header: this.$t('productModal.eventHistory.delivery.header.quantity'),
          key: DELIVERY_HISTORY_TABLE_HEADER.QUANTITY,
        },
        {
          width: '60px',
          key: 'kebab',
        },
      ];
    },
    tableData() {
      return this.deliveryItems
        .filter(TABS_FILTER_PREDICATES[this.activeTab])
        .sort((a, b) => b.deliveryDate - a.deliveryDate);
    },
    tenantName() {
      return this.currentTenant?.name;
    },
  },
  watch: {
    loading() {
      this.$emit('change', 0);
    },
    deliveryItems() {
      this.$emit('change', this.deliveryItems.length);
    },
  },
  methods: {
    formatDate,
    handleAction(action, rowData) {
      switch (action) {
        case this.MORE_ACTIONS.OPEN_DOCUMENT_MODAL:
          this.documentId = rowData.deliverySource.ref;
          break;
        default:
          break;
      }
    },
    handleClose() {
      this.refetch();
      this.deliveryId = null;
    },
    actionsVisibleChange(index, isVisible) {
      this.activeActions = isVisible ? index : null;
    },
    formatDifference(difference) {
      return !isNil(difference)
        ? {
            supplierValue: difference.supplierValue,
            customerValue: difference.customerValue,
            totalValue: difference.supplierValue - difference.customerValue,
          }
        : null;
    },
    navigateYear(direction) {
      switch (direction) {
        case 1:
          this.toDate = this.toDate.plus({ years: 1 });
          break;
        case -1:
          this.toDate = this.toDate.minus({ years: 1 });
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.delivery-history-table {
  .more-btn {
    &.active {
      visibility: visible;
    }
  }
  tr {
    .more-btn {
      visibility: hidden;
    }

    &:hover .more-btn {
      visibility: visible;
    }
  }
}
</style>
