<template>
  <el-tooltip :disabled="!difference" :placement="$t('direction') === 'rtl' ? 'top-end' : 'top-start'">
    <template v-if="difference" #content>
      <p>{{ $t('productModal.eventHistory.differenceTooltip.title') }}</p>
      <table>
        <tbody>
          <tr class="border-bottom border-color">
            <th scope="row" class="font-weight-lighter">
              {{ $t('productModal.eventHistory.differenceTooltip.by', { name: supplierName }) }}
            </th>
            <td class="font-weight-lighter p-1" :class="$direction === 'ltr' ? 'ps-4' : 'pe-4'">
              {{ difference.supplierValue }}
            </td>
          </tr>
          <tr class="border-bottom border-color">
            <th scope="row" class="font-weight-lighter">
              {{ $t('productModal.eventHistory.differenceTooltip.by', { name: businessName }) }}
            </th>
            <td class="font-weight-lighter p-1" :class="$direction === 'ltr' ? 'ps-4' : 'pe-4'">
              {{ difference.customerValue }}
            </td>
          </tr>
          <tr class="border-bottom border-color">
            <th scope="row" class="font-weight-lighter">
              {{ $t('productModal.eventHistory.differenceTooltip.amount') }}
            </th>
            <td class="font-weight-lighter p-1" :class="$direction === 'ltr' ? 'ps-4' : 'pe-4'">
              {{ difference.totalValue }}
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <span :class="{ ['text-warning']: difference }">
      {{ value }}
    </span>
  </el-tooltip>
</template>

<script>
export default {
  props: {
    value: { type: String, required: true },
    supplierName: { type: String, default: null },
    businessName: { type: String, default: null },
    difference: { type: Object, default: null },
  },
};
</script>

<style>
.border-color {
  border-color: rgba(255, 255, 255, 0.2) !important;
}
</style>
