<template>
  <div class="h-100">
    <Tabs :tabs="tabs" :active-tab.sync="activeTab" class="px-5" />
    <div class="px-5 my-4 d-flex flex-row-reverse">
      <PeriodNavigator :to-date="toDate" @forward="navigateYear" @backward="navigateYear" />
    </div>
    <div class="px-5" style="height: calc(100% - 123px)">
      <Table
        v-loading="loading"
        class="shadow order-history-table mh-100"
        rounded
        border
        :data="tableData"
        :columns="columns"
        show-index
        sticky-columns="1"
        expandable
        @row-click="orderId = tableData[$event].orderId"
      >
        <template #cell-product="{ rowData: { product: itemProduct, items } }">
          <div class="d-flex align-items-center position-relative">
            <template v-if="!isNil(items) && items.length">
              <HasAssociatedProductsIcon
                width="16px"
                height="16px"
                class="text-muted position-absolute"
                :style="$direction === 'ltr' ? 'left: -20px; top: 3px' : 'right: -20px; top: 3px'"
              />
            </template>
            <div class="w-100">
              <p class="text-truncate">
                {{ itemProduct.name }}
              </p>
              <small class="d-block text-muted" :style="{ direction: 'ltr' }">
                {{ itemProduct.sku }}
              </small>
            </div>
          </div>
        </template>
        <template #cell-orderDate="{ rowData: { orderDate } }">
          {{ formatDate(orderDate) }}
        </template>

        <template #cell-quantity="{ rowData: { quantity, difference } }">
          <template v-if="isNil(quantity)">-</template>
          <CellWithDifference
            v-else
            :value="Number(quantity).toString()"
            :difference="formatDifference(ORDER_HISTORY_TABLE_HEADER.QUANTITY, difference)"
            :supplier-name="product.business.name"
            :business-name="tenantName"
          />
        </template>

        <template #cell-price="{ rowData: { price, difference } }">
          <template v-if="isNil(price)">-</template>
          <CellWithDifference
            v-else
            :value="formatMoney(price)"
            :difference="formatDifference(ORDER_HISTORY_TABLE_HEADER.PRICE, difference)"
            :supplier-name="product.business.name"
            :business-name="tenantName"
          />
        </template>

        <template #cell-discount="{ rowData: orderItem }">
          <DiscountCell :order-item="orderItem" />
        </template>

        <template #cell-associatedItemsTotalAmount="{ rowData: { associatedIntegralItemsPricePerUnit } }">
          <template v-if="associatedIntegralItemsPricePerUnit">
            {{ formatMoney(associatedIntegralItemsPricePerUnit) }}
          </template>
          <template v-else>-</template>
        </template>

        <template #cell-netPrice="{ rowData: { netAmountPerUnit } }">
          {{ formatMoney(netAmountPerUnit) }}
        </template>

        <template #cell-totalAmount="{ rowData: { totalNetAmount } }">
          {{ formatMoney(totalNetAmount) }}
        </template>

        <template #cell-kebab="{ rowIndex, rowData }">
          <el-dropdown
            class="d-flex"
            trigger="click"
            :placement="$direction === 'rtl' ? 'bottom-start' : 'bottom-end'"
            @command="(action) => handleAction(action, rowData)"
            @visible-change="(isVisible) => actionsVisibleChange(rowIndex, isVisible)"
          >
            <Button type="icon" class="p-0 more-btn" :class="{ active: activeActions === rowIndex }" @click.stop>
              <KebabIcon />
            </Button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="MORE_ACTIONS.OPEN_DOCUMENT_MODAL">
                {{ $t('productModal.eventHistory.order.more.displayDocument') }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
        <template #expandable-content="{ rowData: { items } }">
          <table class="table w-100 sub-table fixed-layout">
            <tbody>
              <tr v-for="associatedItem in items" :key="associatedItem.id">
                <td class="bg-secondary" style="width: 110px"></td>
                <td class="bg-secondary" :style="{ width: columns[0].width }">
                  <div class="d-flex align-items-center position-relative">
                    <template v-if="!isNil(items)">
                      <AssociatedProductIcon
                        width="16px"
                        height="16px"
                        class="position-absolute"
                        :style="$direction === 'ltr' ? 'left: -20px; top: 3px' : 'right: -20px; top: 3px'"
                      />
                    </template>
                    <div class="w-100">
                      <p class="text-truncate">
                        {{ associatedItem.product ? associatedItem.product.name : $t('commons.generalProduct') }}
                      </p>
                      <small class="d-block text-muted">
                        {{ associatedItem.product && associatedItem.product.sku }}
                      </small>
                    </div>
                  </div>
                </td>
                <td class="bg-secondary" :style="{ width: columns[1].width }"></td>
                <td class="bg-secondary" :style="{ width: columns[2].width }">
                  <template v-if="isNil(associatedItem.quantity)">-</template>
                  <CellWithDifference
                    v-else
                    :value="Number(associatedItem.quantity).toString()"
                    :difference="formatDifference(ORDER_HISTORY_TABLE_HEADER.QUANTITY, associatedItem.difference)"
                    :supplier-name="product.business.name"
                    :business-name="tenantName"
                  />
                </td>
                <td class="bg-secondary" :style="{ width: columns[3].width }">
                  <template v-if="isNil(associatedItem.price)">-</template>
                  <CellWithDifference
                    v-else
                    :value="formatMoney(associatedItem.price)"
                    :difference="formatDifference(ORDER_HISTORY_TABLE_HEADER.PRICE, associatedItem.difference)"
                    :supplier-name="product.business.name"
                    :business-name="tenantName"
                  />
                </td>
                <td class="bg-secondary" :style="{ width: columns[4].width }">
                  <template v-if="isNil(associatedItem.discount) || associatedItem.discount === 0">-</template>
                  <CellWithDifference
                    v-else
                    :value="formatPercent(associatedItem.discount)"
                    :difference="formatDifference(ORDER_HISTORY_TABLE_HEADER.DISCOUNT, associatedItem.difference)"
                    :supplier-name="product.business.name"
                    :business-name="tenantName"
                  />
                </td>
                <td class="bg-secondary" :style="{ width: columns[5].width }"></td>
                <td class="bg-secondary" :style="{ width: columns[6].width }"></td>
                <td class="bg-secondary" :style="{ width: columns[7].width }">
                  {{ formatMoney(associatedItem.totalAmount) }}
                </td>
                <td class="bg-secondary" :style="{ width: columns[8].width }"></td>
              </tr>
            </tbody>
          </table>
        </template>
      </Table>
    </div>
    <EventMapModal v-if="orderId" :activity="{ id: orderId, type: 'order' }" @close="handleClose" />
    <DocumentModal v-if="documentId" visible :document-id="documentId" @close="documentId = null" />
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { isNil } from 'ramda';
import { computed, ref } from 'vue';

import { useCurrency } from '@/locale/useCurrency';
import { useTenancy } from '@/modules/auth';
import { KebabIcon, HasAssociatedProductsIcon, AssociatedProductIcon } from '@/assets/icons';
import { EventMapModal } from '@/modules/eventMapModal';
import { DocumentModal } from '@/modules/documentModal';
import { Tabs, Table, Button } from '@/modules/core';

import { formatDate, formatCurrency, formatPercent } from '@/modules/purchase-management/purchaseManagementFormatters';
import CellWithDifference from './CellWithDifference';
import PeriodNavigator from './PeriodNavigator';
import DiscountCell from '@/modules/purchase-management/components/DiscountCell';
import { useProductOrderItemOrderItems } from '../compositions/orderedProductOrderItem';

const ORDER_HISTORY_TABLE_HEADER = {
  PRODUCT: 'product',
  ORDER_DATE: 'orderDate',
  QUANTITY: 'quantity',
  PRICE: 'price',
  DISCOUNT: 'discount',
  ASSOCIATED_ITEMS_TOTAL_AMOUNT: 'associatedItemsTotalAmount',
  NET_PRICE: 'netPrice',
  TOTAL_AMOUNT: 'totalAmount',
};

const MORE_ACTIONS = {
  OPEN_DOCUMENT_MODAL: 'openDocumentModal',
};

const TABS_FILTER_PREDICATES = [(orderItem) => orderItem.quantity >= 0, (orderItem) => orderItem.quantity < 0];

export default {
  components: {
    Tabs,
    Table,
    Button,
    EventMapModal,
    DocumentModal,
    KebabIcon,
    CellWithDifference,
    PeriodNavigator,
    AssociatedProductIcon,
    HasAssociatedProductsIcon,
    DiscountCell,
  },
  props: {
    product: { type: Object, required: true },
  },
  setup(props) {
    const { currentTenant } = useTenancy();
    const { currencyFormat } = useCurrency();

    const toDate = ref(DateTime.local().endOf('month'));
    const fromDate = computed(() => toDate.value.minus({ years: 1 }).startOf('month'));

    const variables = computed(() => ({
      businessId: currentTenant.value.id,
      productId: props.product?.id,
      toDate: toDate.value.toISODate(),
      fromDate: fromDate.value.toISODate(),
    }));

    const { orderItems, loading, refetch } = useProductOrderItemOrderItems(variables);
    return {
      orderItems,
      loading,
      refetch,
      currentTenant,
      toDate,
      isNil,
      currencyFormat,
    };
  },
  data() {
    return {
      ORDER_HISTORY_TABLE_HEADER,
      MORE_ACTIONS,
      activeTab: 0,
      orderId: null,
      documentId: null,
      activeActions: null,
    };
  },
  computed: {
    tabs() {
      return [
        {
          text: this.$t('productModal.eventHistory.order.tab.ordered'),
          badgeValue: this.loading ? 0 : this.orderItems.filter(TABS_FILTER_PREDICATES[0]).length,
        },
        {
          text: this.$t('productModal.eventHistory.order.tab.returnNotice'),
          badgeValue: this.loading ? 0 : this.orderItems.filter(TABS_FILTER_PREDICATES[1]).length,
        },
      ];
    },
    columns() {
      return [
        {
          header: this.$t('terms.supplierTerms.supplierTermTable.header.product'),
          key: ORDER_HISTORY_TABLE_HEADER.PRODUCT,
          width: '22%',
        },
        {
          header: this.$t('productModal.eventHistory.order.header.orderDate'),
          key: ORDER_HISTORY_TABLE_HEADER.ORDER_DATE,
          width: '12%',
        },
        {
          header: this.$t('productModal.eventHistory.order.header.quantity'),
          key: ORDER_HISTORY_TABLE_HEADER.QUANTITY,
          width: '8%',
        },
        {
          header: this.$t('productModal.eventHistory.order.header.price'),
          key: ORDER_HISTORY_TABLE_HEADER.PRICE,
          width: '10%',
        },

        {
          header: this.$t('productModal.eventHistory.order.header.discount'),
          key: ORDER_HISTORY_TABLE_HEADER.DISCOUNT,
          width: '10%',
        },

        {
          header: this.$t('productModal.eventHistory.order.header.associatedItems'),
          key: ORDER_HISTORY_TABLE_HEADER.ASSOCIATED_ITEMS_TOTAL_AMOUNT,
          width: '12%',
        },
        {
          header: this.$t('productModal.eventHistory.order.header.netPrice'),
          key: ORDER_HISTORY_TABLE_HEADER.NET_PRICE,
          width: '10%',
        },

        {
          header: this.$t('productModal.eventHistory.order.header.totalAmount'),
          key: ORDER_HISTORY_TABLE_HEADER.TOTAL_AMOUNT,
          width: '10%',
        },
        {
          header: '',
          width: '60px',
          key: 'kebab',
        },
      ];
    },
    mutatedOrderItems() {
      const ASSOCIATED_INTEGRAL_ITEM_TYPE = 'associatedIntegral';
      return this.orderItems.map((orderItem) => {
        return {
          ...orderItem,
          expandable: orderItem.items?.length,
          expandableCustomClass: 'p-0',
          items: orderItem.items?.filter((item) => item.type === ASSOCIATED_INTEGRAL_ITEM_TYPE),
        };
      });
    },
    tableData() {
      return this.mutatedOrderItems
        .filter(TABS_FILTER_PREDICATES[this.activeTab])
        .sort((a, b) => b.orderDate - a.orderDate);
    },
    tenantName() {
      return this.currentTenant?.name;
    },
  },
  watch: {
    loading() {
      this.$emit('change', 0);
    },
    orderItems() {
      this.$emit('change', this.orderItems.length);
    },
  },
  methods: {
    formatDate,
    formatMoney(number) {
      return formatCurrency(number, this.currencyFormat);
    },
    formatPercent(number) {
      const floatFractionDigits = Math.min(2, number.toString().split('.')[1]?.length ?? 0);
      return formatPercent(number, floatFractionDigits);
    },
    handleAction(action, rowData) {
      switch (action) {
        case this.MORE_ACTIONS.OPEN_DOCUMENT_MODAL:
          this.documentId = rowData.orderSource.ref;
          break;
        default:
          break;
      }
    },
    handleClose() {
      this.refetch();
      this.orderId = null;
    },
    actionsVisibleChange(index, isVisible) {
      this.activeActions = isVisible ? index : null;
    },
    formatDifference(type, difference) {
      if (isNil(difference) || isNil(difference[type])) return null;

      const { supplierValue, customerValue } = difference[type];
      switch (type) {
        case ORDER_HISTORY_TABLE_HEADER.QUANTITY:
          return {
            supplierValue,
            customerValue,
            totalValue: supplierValue - customerValue,
          };
        case ORDER_HISTORY_TABLE_HEADER.PRICE:
          return {
            supplierValue: this.formatMoney(supplierValue),
            customerValue: this.formatMoney(customerValue),
            totalValue: this.formatMoney(supplierValue - customerValue),
          };
        case ORDER_HISTORY_TABLE_HEADER.DISCOUNT:
          return {
            supplierValue: this.formatPercent(supplierValue),
            customerValue: this.formatPercent(customerValue),
            totalValue: this.formatPercent(supplierValue - customerValue),
          };
        default:
          return null;
      }
    },
    navigateYear(direction) {
      switch (direction) {
        case 1:
          this.toDate = this.toDate.plus({ years: 1 });
          break;
        case -1:
          this.toDate = this.toDate.minus({ years: 1 });
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.order-history-table {
  .more-btn {
    &.active {
      visibility: visible;
    }
  }
  tr {
    .more-btn {
      visibility: hidden;
    }

    &:hover .more-btn {
      visibility: visible;
    }
  }
}
</style>
