import { render, staticRenderFns } from "./OrderedProductPricing.vue?vue&type=template&id=5358efaa&scoped=true"
import script from "./OrderedProductPricing.vue?vue&type=script&lang=js"
export * from "./OrderedProductPricing.vue?vue&type=script&lang=js"
import style0 from "./OrderedProductPricing.vue?vue&type=style&index=0&id=5358efaa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5358efaa",
  null
  
)

export default component.exports